<template>
    <div class="take-lead">
        <div class="info-card">
            <h2>Client Details</h2>
            <p><strong>Name:</strong> {{ clientName }}</p>
            <button class="take-lead-btn" @click="takeLead">Take Lead</button>
        </div>
    </div>
</template>

<script>
export default {
    name: "TakeLead",
    data() {
        return {
            clientName: '',
            leadId: '',
        };
    },
    methods: {
        fetchClientDetails() {
            let that = this;
            that.axios
                .get("get-lead-client-data/" + that.$route.params.dialer)
                .then(function (response) {
                    that.clientName = response.data.data.client_name;
                    that.leadId = response.data.data.lead_id;
                });
        },
        takeLead() {
            let that = this;
            that.axios
                .get("take-lead/" + that.$route.params.dialer)
                .then(function () {
                    window.location.href = `/lead/${that.leadId}#lead-info`;
                });
        },
    },
    mounted() {
        this.fetchClientDetails();
    },
};
</script>

<style scoped>
.take-lead {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* Center vertically and horizontally */
    background-color: #f3f4f6;
    /* Light gray background for the whole page */
    margin: 0;
    padding: 0;
}

.info-card {
    width: 100%;
    max-width: 400px;
    /* Limit card width for better layout */
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 12px;
    /* Smoother corners */
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Subtle shadow for depth */
    text-align: center;
}

.info-card h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
    font-weight: bold;
}

.info-card p {
    margin: 10px 0;
    font-size: 16px;
    color: #555;
}

.take-lead-btn {
    display: inline-block;
    margin-top: 20px;
    padding: 12px 25px;
    background-color: #007bff;
    /* Primary blue */
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    /* Capitalized text */
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    /* Smooth hover effects */
}

.take-lead-btn:hover {
    background-color: #0056b3;
    /* Darker blue on hover */
    transform: translateY(-2px);
    /* Slight lift effect */
}

.take-lead-btn:active {
    background-color: #003f7f;
    /* Even darker blue when active */
    transform: translateY(0);
    /* Reset lift effect */
}
</style>
